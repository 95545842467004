import React, { useState } from 'react';
import { qaRob } from '../utils/api';
import SourceDropdownCard from '../components/Source';


const Rob = () => {
  const [question, setQuestion] = useState('');
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleQuestionSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const response = await qaRob(question);
    console.log(response)
    setResponse(response);
    setLoading(false);
  };


  return (
    <div className="container mx-auto pt-10">
      
      <h1 className="text-3xl font-bold mb-4 text-center">Ask Rob Blackie a Question</h1>
      <p className="text-center italic mb-4">
        Enter a question and receive an answer based on the London Liberal Democrats' mayoral manifesto 
      </p>
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <form onSubmit={handleQuestionSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="question">
              Enter your question here:
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="question"
              type="text"
              value={question}
              onChange={handleQuestionChange}
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
        {response && (
        <div className="my-4 flex justify-center">
          <img src="blackie.png" alt="Rob Blackie" style={{width: '20%', height: 'auto'}} />
        </div>
        )}
        {response && (
          <div className="mt-4">
            <h2 className="font-bold">Rob's Response:</h2>
            <p className="text-gray-700 text-base">{response.result}</p>
            <div className="mt-4">
              <p className="font-bold">Sources:</p>
              {response.sources.map((source, i) => (
                <SourceDropdownCard key={i} source={source} />
              ))}
            </div>
          </div>
        )}
      </div>
      {loading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Rob;
