import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './index.css';
import Email from './pages/email';
import Documents from './pages/documents';
import ReactGA from 'react-ga4';
import Rob from './pages/rob';


ReactGA.initialize('G-85VNHZMJN1');

const App = () => {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<Email />} />
          <Route path="/docs" element={<Documents />} />
          <Route path="/rob4london" element={<Rob />} />
        </Routes>
    </Router>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);