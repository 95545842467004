import axios from 'axios';
import config from '../config';

const currentEnv = process.env.NODE_ENV || 'development';

console.log(`Current Environment: ${currentEnv}`);

const apiBaseUrl = config[currentEnv].apiBaseUrl;

const api = axios.create({
  baseURL: apiBaseUrl,
});


export const formatEmailRequest = (emailInput) => {
  return {
    data_source: emailInput.dataSource,
    email: {
      subject: emailInput.subject,
      body: emailInput.body,
    },
  };
};


export const generateEmails = async (emailInput) => {
  try {
    const emailRequest = formatEmailRequest(emailInput)
    const response = await api.post('/email', emailRequest);
    console.log(response.data);
    return [response.data];
  } catch (error) {
    console.error('Error generating responses:', error);
    return [];
  }
};

export const getSources = async () => {
  try {
    const response = await api.get('/sources');
    return response.data;
  } catch (error) {
    console.error('Error fetching sources:', error);
    return [];
  }
};

export const getDocuments = async (source) => {
  try {
    const response = await api.get(`/documents/${source}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching documents:', error);
    return [];
  }
};

export const qaRob = async (question) => {
  try {
    const response = await api.post('/qa', { question });
    return response.data;
  } catch (error) {
    console.error('Error fetching QA response:', error);
    return [];
  }
};
