import React from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';

const SourceDropdownCard = ({ source }) => {
  const generate_title = () => {
    if (source.metadata && source.metadata.title) {
      return source.metadata.title;
    } else if (source.metadata && source.metadata.subject) {
      return `Pre-Manifesto Policy: ${source.metadata.subject}`;
    } else if (source.metadata && source.metadata.source) {
      return format_filename(source.metadata.source);
    } else {
      return "Unknown Source";
    }
  };

  const generate_body = () => {
    return source.page_content;
  };

  const format_filename = (path) => {
    let filename = path.split('/').pop().split('.')[0];
    let normal_text = filename.replace(/_/g, ' ').replace(/-/g, ' ');
    return normal_text.charAt(0).toUpperCase() + normal_text.slice(1);
  };

  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="dropdown-card">
      <h3
        className="dropdown-card-title flex items-center justify-between px-4 py-2 bg-gray-100 hover:bg-gray-200 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{generate_title()}</span>
        {isOpen ? (
          <ChevronUpIcon className="h-5 w-5 text-gray-500" />
        ) : (
          <ChevronDownIcon className="h-5 w-5 text-gray-500" />
        )}
      </h3>
      {isOpen && (
        <div className="dropdown-card-body px-4 py-2">{generate_body()}</div>
      )}
    </div>
  );
};

export default SourceDropdownCard;
