import React, { useState, useEffect } from 'react';
import { getSources, getDocuments } from '../utils/api';

const Documents = () => {
  const [dataSource, setDataSource] = useState('Select Data Source');
  const [sources, setSources] = useState([]);
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    const fetchSources = async () => {
      const sources = await getSources();
      setSources(sources);
    };
    fetchSources();
  }, []);

  useEffect(() => {
    const fetchDocuments = async () => {
      if (dataSource !== 'Select Data Source') {
        const documents = await getDocuments(dataSource);
        setDocuments(documents);
      }
    };
    fetchDocuments();
  }, [dataSource]);

  return (
    <div className="container mx-auto pt-10">
      <h1 className="text-3xl font-bold mb-4 text-center">Documents</h1>
      <select value={dataSource} onChange={(e) => setDataSource(e.target.value)}>
        <option disabled>Select Data Source</option>
        {sources.map((source, index) => (
          <option key={index} value={source}>{source}</option>
        ))}
      </select>
      <div className="documents-list" style={{ overflowY: 'scroll', maxHeight: '500px' }}>
        {[...new Set(documents.map(document => document.metadata['source'] || 'unknown'))].map((source, index) => (
          <div key={index} style={{ marginBottom: '10px' }} className="document-card">
            <p>{index + 1}. {source}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Documents;
