import React, { useState } from 'react';
import SourceDropdownCard from './Source';


const ResponseList = ({ responses, onNewInput }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      {responses.length > 1 && (
        <div className="flex flex-wrap space-x-4 mb-4">
          {responses.map((_, index) => (
            <button
              key={index}
              className={`${
                index === currentIndex ? 'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline' : 'bg-gray-200 hover:bg-gray-300 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
              }`}
              onClick={() => setCurrentIndex(index)}
            >
              Response {index + 1}
            </button>
          ))}
        </div>
      )}
      <div className="relative">
        {responses.map((response, index) => (
          <div
            key={index}
            className={`${
              index === currentIndex ? 'block' : 'hidden'
            } transition-opacity duration-300`}
          >
            {response.result.subject === "" && response.result.body === "" ? (
              <p className="text-gray-700 text-base">Unable to generate an email response for this query</p>
            ) : (
              <>
                <p className="font-bold">Subject: {response.result.subject}</p>
                <br/>
                <p className="text-gray-700 text-base">{response.result.body.split('\n').map((line, i) => <span key={i}>{line}<br/></span>)}</p>
                <div className="mt-4">
                  <p className="font-bold">Sources:</p>
                  {response.sources.map((source, i) => (
                    <SourceDropdownCard key={i} source={source} />
                  ))}
                </div>
              </>
            )}
          </div>
        ))}
      </div>
      <button
        className="mt-6 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        onClick={onNewInput}
      >
        New Input
      </button>
    </div>
  );
};

export default ResponseList;