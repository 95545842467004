import React, { useState, useEffect } from 'react';
import EmailInput from '../components/EmailInput';
import ResponseList from '../components/ResponseList';
import { generateEmails } from '../utils/api';

const Email = () => {
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleEmailSubmit = async (emailInput) => {
    setLoading(true);
    const generatedEmails = await generateEmails(emailInput);
    setResponses(generatedEmails);
    setLoading(false);
  };

  const handleNewInput = () => {
    setResponses([]);
  };

  return (
    <div className="container mx-auto pt-10">
      <h1 className="text-3xl font-bold mb-4 text-center">Hi, welcome to  Pimlico Email !</h1>
      <p className="text-center italic mb-4">
        This AI searches through documents on our large data repositories including manifestos, speeches, and policy documents. It then generates an email response to the email input below using this information.
        <br />
        If any content produced is incorrect or offensive, please email us at <a href="mailto:hello@pimlico.tech" style={{ color: 'blue' }}>hello@pimlico.tech</a>.
      </p>
      {responses.length === 0 ? (
        <EmailInput onSubmit={handleEmailSubmit} />
      ) : (
        <ResponseList responses={responses} onNewInput={handleNewInput} />
      )}
      {loading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Email;